const routes = [
  {
      name: 'sharePage',
      path: '/',
      redirect: '/articleDetail',
      children: [
        {
          name: 'articleDetail',
          path: '/articleDetail',
          component: () => import('@/view/ArticleDetail')
        },
        {
          name: 'classDetail',
          path: '/classDetail',
          component: () => import('@/view/ClassDetail')
        },
        {
          name: 'worksDetail',
          path: '/worksDetail',
          component: () => import('@/view/WorksDetail')
        }
      ]
  },
]

export default routes
