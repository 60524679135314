import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import 'lib-flexible'
import { vant } from '../vant.config'

import '@/style/index.css' // 全局css样式

const app = createApp(App)
vant(app)
app.use(router)
app.mount('#app')
